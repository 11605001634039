
const LeagueRulesCQuads = () => {
    return (
        <>
            <div>
                <details>
                    <summary className="league-title">League Rules - C Quads</summary>
                        <div className="league-rules">
                        <ul>
                            <li>Teams must have at least two players to play</li>
                            <li>No gender requirement unless otherwise specified by league</li>
                            <li>No positions, only rotation that is required is serving order</li>
                            <li>Double contacts on sets are not allowed</li>
                            <li>No open-hand tips</li>
                            <li>No setting first ball in serve receive</li>
                            <li>No setting first ball on defense unless hard driven</li>
                        </ul>
                        </div>
                </details>
            </div>
        </>
    )
}

export default LeagueRulesCQuads