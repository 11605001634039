import React from 'react'
import useAuth from '../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useGetSessionsQuery } from '../features/sessions/sessionApiSlice'
import '../../src/SVLSide.css'

const SVLLandingPage= () => {
    const { username } = useAuth()

    const navigate = useNavigate()

    const { session } = 
    useGetSessionsQuery("sessionsList", {
        selectFromResult: ({ data }) => ({
            session: data?.entities
        }),
    })

    const sessionsInReg = () => {
        if(!session) {
            return <p>No Sessions Yet</p>
        } else {
            const filteredSessions = Object.values(session).filter((status) => status.status[0] === "Registration" && (username === status.svl))
            const sortedSessions = filteredSessions.sort((a,b) => a.startDateTime > b.startDateTime ? 1 : -1)
            const sessionsArray = sortedSessions.map((sessions) => {
                const startDate = new Date(sessions.startDateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })
                const endDate = new Date(sessions.endDateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })
                const navToSessions = (e) => {
                    e.preventDefault()
                    navigate(`../sessions/${sessions.id}`)
                }
                return (
                    <>
                    <p className='dash-leagues' onClick={navToSessions}>{sessions.name} - {startDate} : {endDate}</p>
                    </>
                )
            })
            return sessionsArray
        }
    }

    const activeSessions = () => {
        if(!session) {
            return <p>No Sessions Yet</p>
        } else {
            const filteredSessions = Object.values(session).filter((status) => status.status[0] === "Active" && (username === status.svl))
            const sortedSessions = filteredSessions.sort((a,b) => a.startDateTime > b.startDateTime ? 1 : -1)
            const sessionsArray = sortedSessions.map((sessions) => {
                const startDate = new Date(sessions.startDateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })
                const endDate = new Date(sessions.endDateTime).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })
                const navToSessions = (e) => {
                    e.preventDefault()
                    navigate(`../sessions/${sessions.id}`)
                }
                return (
                    <>
                    <p className='dash-leagues' onClick={navToSessions}>{sessions.name} - {startDate} : {endDate}</p>
                    </>
                )
            })
            return sessionsArray
        }
    }


    return (
    <>
    <h1 className='league-title'>{username}</h1>
    <div className='SVL-landing-page'>
        <div className='SVL-landing-links-container'>
        <h3 className='SVL-landing-links-header'>My Links</h3>
        <div className='SVL-landing-links'>
            <p className='dash-leagues'><Link to="/dash/playerinfo">My Info</Link></p>
            <p className='dash-leagues'><Link to="/dash/playercontact">Contact Volleysub</Link></p>
        </div>
        </div>
        <div className='SVL-landing-sessions-container'>
        <h3 className='player-dash-headers'>Sessions</h3>
            <div className='SVL-landing-links-sessions'>
            <p className='dash-leagues'><Link to="/dash/sessions/new">Create New Session</Link></p>
            <p className='dash-leagues'><Link to="/dash/sessions">View Sessions</Link></p>
            <h4 className='league-subheaders'>Sessions in Registration</h4>
                {sessionsInReg()}
            <h4 className='league-subheaders'>Active Sessions</h4>
                {activeSessions()}
            </div>
        </div>
    </div>
    </>
    )
}

export default SVLLandingPage