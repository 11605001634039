import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import useTitle from '../../hooks/useTitle'
import PulseLoader from 'react-spinners/PulseLoader'
import VSMultiHeader from '../../components/VSLoginHeader'
import VSMultiFooter from '../../components/VSLoginFooter'
import '../../multiForms.css'

const Login = () => {
    useTitle('Login')

    const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    //eslint-disable-next-line
    const [persist, setPersist] = usePersist()
    

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    const handleToggle = () => setPersist(prev => !prev)

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            handleToggle()
            navigate('/dash')   
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }


    const handleForgotPassword = () => {
        navigate('/forgotPassword')
    }

    const handleNewAccount = () => {
        navigate('/new')
    }

    const handleUserInput = (e) => setUsername(e.target.value.toLowerCase())
    const handlePwdInput = (e) => setPassword(e.target.value)
    
    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <div>
        <VSMultiHeader />
        <section className="PlayerLoginFormContainer">
            <header>
                <h1 className='PlayerSignUpTitle'>Login</h1>
            </header>
            <main className="PlayerLoginForm">
                <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>

                <form className="form" onSubmit={handleSubmit}>
                    <label htmlFor="username">Username
                    <input
                        className="form__input"
                        type="text"
                        id="username"
                        ref={userRef}
                        value={username}
                        onChange={handleUserInput}
                        autoComplete="off"
                        required
                    />
                    </label>
                    <label htmlFor="password">Password
                    <input
                        className="form__input"
                        type="password"
                        id="password"
                        onChange={handlePwdInput}
                        value={password}
                        required
                    />
                    </label>
                    <div className="persistCheck">
                    <input
                        type="checkbox"
                        id="persist"
                        defaultValue={true}
                        checked={persist}
                    />
                    <label htmlFor="persist">Trust This Device</label>
                </div>
                    <button className="FormSubmitButton">Login</button>

                </form>
                <div className='login-option-container'>
                    <p className='login-options' onClick={handleNewAccount}>Sign Up|</p>
                    <p className='login-options' onClick={handleForgotPassword}>Forgot Password?</p>
                </div>
            </main>
        </section>
        <VSMultiFooter />
        </div>
    )

    return content
}
export default Login